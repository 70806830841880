import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import ErrorPage from 'next/error'
import {AppProvider, withApp} from '../store/app'
import I18nProvider, {withI18n} from '../store/i18n'
import {useFlow} from '../store/flow'
import {useBanners} from '../store/banners'
import {datadogRum} from '@datadog/browser-rum'

// FilePond
import * as FilePond from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import FilePondPluginImageEditor from '../../lib/filepond/FilePondPluginImageEditor.esm'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'

import Navstory from '../misc/navstory'

import shortid from 'shortid'
import {default as nextCookies} from 'next-cookies'
import {withQuery, getUserIp, utmsFromQuery, asArray, string} from '../../lib/utils'
import Api from '../../lib/api'
import Auth from '../../lib/auth'
import Tracker from '../../lib/tracker'
import Cookies from '../../lib/cookies'
import Page from '../../lib/page'
import {ToastProvider, Toast, ToastContainer, withToaster} from '../../lib/toast'
import Cloudinary from '../../lib/cloudinary'
import '../../lib/rum'

import Router from 'next/router'
import {FlowStep} from '../../lib/constants'
import Bowser from 'bowser'
import Beacons from '../../lib/beacons'
import {usePanel} from '../store/panels'
import ABTest from '../../lib/abtest'
import Products from '../../lib/products'
import Memories from '../../lib/memories'
import Profile from '../../lib/profile'

const redirect = (res, url) => {
  res.writeHead(301, {
    'Cache-Control': 'private, no-cache, no-store, must-revalidate',
    Expires: '-1',
    Pragma: 'no-cache',
    Location: `${url || '/'}`
  })
  res.end()
}

// on each API call, set the current browser url
const apiMW = function (options) {
  const app = this
  const url = app.slug
    ? `${process.env.WV2_URL}/${app.slug}${(app.query.flow && `/${app.query.flow}`) || ''}`
    : `${process.env.WV2_URL}${app.pathname}`

  // console.log('url', url)
  options.headers['browser-url'] = url
}

// Router.events.on('routeChangeStart', (data) => {
//   // console.log('routeChangeStart', data)
// })

// prototype localStorage get/set object method
if (process.browser) {
  Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value))
  }
  Storage.prototype.getObject = function (key) {
    var value = this.getItem(key)
    return value && JSON.parse(value)
  }
}

const firstLevelFlows = ['', 'tab-infos', 'tab-share', 'tab-memories', 'tab-settings', 'onboarding']
const specialFlows = [
  'swap',
  'logout',
  'stop-assistance',
  'unsubscribe',
  'confirm',
  'access-confirm'
]

const cookiesPrefix = 'im_web_'

const cloudinary = Cloudinary({
  mock: process.env.MOCK_PHOTOS == 'true',
  mockId: 'localhost_rezwzc',
  presets: {profile: 'gpjb3xkt', memory: 'oywdbnyz', kyc: 'h6kidx83', letter: 'ao8iigw7'}
})

const App = ({err, children, app, i18n}) => {
  // https://nextjs.org/docs/advanced-features/custom-error-page
  // Error page takes a title (when we decide to i18n this page)
  if (err) return <ErrorPage title={err} />

  const {creds, query} = app
  const c = Cookies({prefix: cookiesPrefix})
  const [video, setVideo] = useState(false)
  const [page, setPage] = useState(app.page)
  const [profile, setProfile] = useState(app._profile)
  const [orders, setOrders] = useState([])
  const [locations, setLocations] = useState([])
  const [navstory, setNavstory] = useState([])
  const [tracking, setTracking] = useState(app.tracking)
  const [tempValues, setTempValues] = useState({})
  const [navbarHidden, setNavbarHidden] = useState(false)
  const [blockEdit, setBlockEdit] = useState(false)
  const [lastUIRef, setLastUIRef] = useState(false)
  const [features, setFeatures] = useState(app.features)

  app.trackEvent = async (event, ctx = {}, centric = 'page') => {
    const body = {
      slug: (app.page && app.page.slug) || 'page-analytic',
      event: event,
      properties: {ctx},
      centric
    }

    await app.api.post(`/analytics/track`, {body})
  }

  app.hasRole = (roles) => {
    if (!Array.isArray(roles)) {
      roles = asArray(roles)
    }

    const matchs = []
    roles.forEach((r) => {
      if (app._role[`is${string.capitalize(r)}`]) matchs.push(r)
    })
    return matchs.length ? matchs : false
  }

  // page
  app.page = page
  app.setPage = setPage

  // tracking info
  app.tracking = tracking
  app.setTracking = (key, value) => {
    if (value) setTracking({...tracking, [key]: value})
    else setTracking({...tracking, ...key})
  }

  // navstroy
  app.navstory = navstory
  app.setNavstory = setNavstory

  app.navbarHidden = navbarHidden
  app.setNavbarHidden = setNavbarHidden

  app.blockEdit = blockEdit
  app.setBlockEdit = setBlockEdit

  // panel
  app.panel = usePanel()

  // profile
  app._profile = profile
  app.setProfile = setProfile
  app.Profile = Profile({
    api: app.api,
    profile: app._profile,
    setProfile: app.setProfile,
    panel: app.panel,
    tribe: app._tribe,
    hasRole: app.hasRole
  })

  //orders
  app.orders = orders
  app.setOrders = setOrders

  //locations
  app.locations = locations
  app.setLocations = setLocations

  // cloudinary
  app.cloudinary = cloudinary

  // video
  app.video = video
  app.setVideo = setVideo

  // temp values
  app.tempValues = tempValues
  app.setTempValues = setTempValues

  // ui ref
  app.lastUIRef = lastUIRef
  app.setLastUIRef = setLastUIRef

  // features
  app.features = features
  app.setFeatures = setFeatures

  // cookies
  app.cookies = c

  //ABTest
  app.ABTest = ABTest({
    analytics: app._analytics,
    profile: app._profile,
    trackEvent: app.trackEvent
  })

  // handle missing email in profiles
  let defaultFlow = FlowStep.default
  let startFlow = query.flow || ''
  let startProps = {}
  const isSpecialFlow = specialFlows.includes(startFlow)

  if (!isSpecialFlow) {
    if (!isSpecialFlow && !app.authed) startFlow = FlowStep.onboarding

    if (!app.Profile.isComplete() && firstLevelFlows.includes(startFlow)) {
      startFlow = FlowStep.profileSetup
    }

    if (
      app._profile &&
      app.hasRole('manager') &&
      !app.hasRole('team') &&
      app.page.partner._id &&
      (!app._profile.checklist || !app._profile.checklist['splash-manager'])
    ) {
      if (app.ABTest.isMobile()) {
        startFlow = FlowStep.tabMemories
        startProps = {splash: true}
      } else startFlow = FlowStep.splash
    }
  }

  // nav
  app.flow = useFlow({
    defaultFlow,
    startFlow,
    urlPrefix: page ? `/${page.slug}` : '',
    navstory,
    setNavstory,
    startProps,
    trackEvent: app.trackEvent,
    lastUIRef: lastUIRef,
    setLastUIRef: setLastUIRef
  })

  // fullscreen
  const [fullscreen, setFullscreen] = useState(startFlow == 'onboarding')
  app.fullscreen = fullscreen
  app.setFullscreen = setFullscreen

  // api
  app.api = new Api({
    ...app.apiOptions,
    mw: apiMW.bind(app),
    context: _.pick(app, ['lastUIRef', 'setFeatures'])
  })

  // tracker
  app.tracker = Tracker({slug: page.slug, api: app.api})

  // banners
  app._bnr = useBanners()

  // Services
  // Auth
  app.Auth = Auth({api: app.api})

  // products
  app.Products = Products({page: app.page, api: app.api, hasRole: app.hasRole})

  // Page
  app.Page = Page({
    api: app.api,
    page: app.page,
    setPage: app.setPage,
    setOrders: app.setOrders,
    setMemories: app.setMemories,
    locations: app.locations,
    setLocations: app.setLocations,
    Products: app.Products
  })

  //Memories
  app.Memories = Memories({page: app.page, api: app.api})

  //Beacons
  app.Beacons = Beacons({profile: app._profile, page: app.page, hasRole: app.hasRole})

  if (typeof window !== 'undefined') {
    window.state = app
    window.Cookies = Cookies()
    window.Bowser = Bowser.getParser(window.navigator && window.navigator.userAgent)
    window.Browser = window.Bowser.getResult()
  }

  useEffect(() => {
    // https redir
    if (app.env.prod && location.protocol == 'http:') {
      location.href = location.href.replace(/^http:/, 'https:')
      return
    }

    //AB Test listener
    app.ABTest.initListeners()
    app.ABTest.showDebugger()

    // delete passed jwt when passed
    const url = new URL(window.location)
    if (url.searchParams.get('jwt')) {
      url.searchParams.delete('jwt')
      history.replaceState(null, null, url)
    }

    //load DD data
    app._profile &&
      app._profile._id &&
      datadogRum.setUser({id: app._profile._id, name: app._profile.email, email: app._profile.name})

    //init FilePond
    FilePond.registerPlugin(
      FilePondPluginImageExifOrientation,
      FilePondPluginImagePreview,
      FilePondPluginFileValidateType,
      FilePondPluginFileValidateSize,
      FilePondPluginImageTransform,
      FilePondPluginImageValidateSize,
      FilePondPluginImageEditor,
      FilePondPluginImageCrop,
      FilePondPluginImageResize,
      FilePondPluginFilePoster
    )

    //  load app data
    app.Memories.load()
    app.Page.loadLocations()

    //load products catalog
    app.Products.load()

    // set cookies
    if (creds.jwt && creds.jwt != c.get('jwt')) c.set('jwt', creds.jwt, {expires: Infinity})
    if (c.get('session')) c.remove('session')
    if (creds.fprint && creds.fprint != c.get('fprint'))
      c.set('fprint', creds.fprint, {expires: Infinity})

    // cache i18n
    // if(setCache) localStorage.setObject('im_reach', reach)
    // if(setCache) localStorage.setObject('im_i18n', i18n)

    // History
    // we don't use next/router, but history.js, but next trigger an error on back button, so we return false here
    Router.beforePopState(() => {
      return false
    })
    History.Adapter.bind(window, 'statechange', function () {
      try {
        setTimeout(() => {
          const state = History.getState().data
          let {flow, props, scroll = {}, isPrev} = state
          const {navstory, setNavstory} = app
          const {setCurrent, navIndex, setNavIndex} = app.flow

          // differentiate between internal pushState & browser back/forward external
          const currentIndex = History.getCurrentIndex()
          const internal = state.index == currentIndex
          // console.log('index', state.index, currentIndex)

          // set animation
          const flowContainer = $('.flow-container')
          flowContainer.addClass('d-none').removeClass('fadeIn')
          setTimeout(() => flowContainer.removeClass('d-none').addClass('animated fadeIn'), 1)

          let prevFlow, prevProps, prevBtn, nextBtn

          if (navstory.length > 1) {
            ;[prevFlow, prevProps] = navstory[1] || []
          }

          if (internal) {
            app.flow.setCurrent(flow)
            app.query.flow = flow // mutate app.query.flow
          } else {
            flow = flow || prevFlow
            props = Object.keys(props || {}).length ? props : prevProps || {}

            if (!flow) {
              console.log('no flow')
            } else {
              app.flow.propsByBlock[flow] = props
              setCurrent(flow)
              app.query.flow = flow // mutate app.query

              if (navstory.length == 1 && flow != navstory[0][0]) nextBtn = true
              if (navstory.length > 1) {
                if (flow == navstory[1][0]) prevBtn = true
                else nextBtn = true
              }

              // next button
              if (nextBtn) {
                setNavIndex(navIndex + 1)
                setNavstory([[flow, props, navIndex + 1], ...navstory])
                if (props.uiref) {
                  setLastUIRef(props.uiref)
                }
              }

              // prev button
              if (prevBtn) {
                const popnav = [...navstory]
                popnav.shift()
                setNavIndex(navIndex - 1)
                setNavstory(popnav)

                if (popnav[0][1] && popnav[0][1].uiref) {
                  setLastUIRef(popnav[0][1].uiref)
                }
              }
            }
          }

          // set new flow or default ()
          // console.log('[flow]', flow, props)

          // scroll to previous position, or to 0
          setTimeout(() => {
            if (isPrev) document.documentElement.scrollTop = document.body.scrollTop = scroll.prev
            else document.documentElement.scrollTop = document.body.scrollTop = 0
          }, 1)
        }, 0)
      } catch (e) {
        console.log('catchhhh', e)
      }
    })
  }, [])

  return (
    <I18nProvider i18n={i18n}>
      <AppProvider value={{...app}}>
        <ToastProvider components={{Toast, ToastContainer}} placement='bottom-left'>
          {false && <Navstory story={app.navstory} />}
          {children}
        </ToastProvider>
      </AppProvider>
    </I18nProvider>
  )
}

App.getInitialProps = async (ctx) => {
  try {
    const {req, res, query, pathname} = ctx
    const {headers} = req
    const {slug = '', catchall} = query
    const subdomain = (headers.host || '').split('.')[0]

    const cookies = nextCookies(ctx)
    const c = {get: (k) => cookies[`${cookiesPrefix}${k}`]}
    let app = {}

    if (slug.startsWith('apple-touch')) return redirect(res, `/img/brand/apple-touch-icon.png`)
    if (/[^a-z0-9-]/.test(slug)) {
      console.log(`[slug:invalid]`, slug)
      return redirect(res, `/`)
    }
    if ('fonts,i18n,img,js,scss'.split(',').includes(slug)) {
      console.log(`[slug:invalid]`, slug)
      return redirect(res, `/`)
    }

    app.env = {
      prod: process.env.APP_ENV == 'production',
      dev: process.env.APP_ENV == 'dev',
      local: process.env.APP_ENV == 'local',
      debug: process.env.APP_ENV != 'production'
    }

    app.slug = slug
    app.query = query
    app.pathname = pathname
    app.utms = utmsFromQuery(query)
    app.creds = {
      jwt: query.jwt || c.get('jwt'),
      session: c.get('session') || shortid.generate(),
      fprint: c.get('fprint') || shortid.generate()
    }

    // tracking data
    let trackingCookie = {}
    try {
      trackingCookie = JSON.parse(cookies['im_mkt_tracking'] || '{}')
    } catch (e) {
      console.log(e)
    }

    app.tracking = {
      ...trackingCookie,
      ...(headers.referer && {referrer: headers.referer}),
      ...query
    }
    if (app.tracking.gclid) app.tracking.origin = 'paid'

    app.apiOptions = {
      baseUrl: process.env.API_URI,
      creds: app.creds,
      utms: app.utms,
      headers: {
        appclient: 'wv2',
        ...(req && {'x-forwarded-for': getUserIp(req)}),
        ..._.pick(headers, 'user-agent'),
        ...(query.ref && {refprofile: query.ref})
      },
      append: {...(query.ref && {ref: query.ref})},
      debug: true,
      mw: apiMW.bind(app)
    }
    // console.log('apiOptions', app.apiOptions, headers)

    // some routes are only transition pages
    app.noReach = specialFlows.includes(app.query.flow)

    // get reach data
    const api = new Api(app.apiOptions)

    // reach API
    const body = app.noReach ? {noReach: true} : {}
    let page, reach
    try {
      reach = (await api.get(slug ? `/pages/${slug}/reach` : `/app/reach`, {body})) || {}
      page = reach.page
    } catch (e) {
      console.log(e)
      return {err: 'Inmemori service unreachable, try again in a few moment please!'}
    }

    // get i18n data
    const i18n = req
      ? await I18nProvider.getInitialProps(ctx, reach.dicos, {
          supported: (process.env.LANGS || '').split(','),
          fallback: 'en',
          cookie: 'im_lang'
        })
      : localStorage.getObject('im_i18n')

    const catchRouteInfo = (path, qs) => {
      // { foo: 'bar', catchall: [ 'content', 'article-blog', 'november' ] }
      qs = {...qs} // don't work by reference
      const subpath = (qs.catchall && `/${qs.catchall.join('/')}`) || ''
      delete qs.catchall
      return withQuery(`${path}${subpath}`, qs)
    }

    const slugRouteInfo = (path, qs) => {
      // { slug: 'content', flow: 'article-blog', utm_source: 'api' }
      qs = {...qs} // don't work by reference
      let subpath = `/${qs.slug}`
      if (qs.flow) subpath = `${subpath}/${qs.flow}`
      delete qs.slug
      delete qs.flow
      return withQuery(`${path}${subpath}`, qs)
    }

    // handle app redirection to ghost sites if needed
    let redirectUrl = `https://${i18n.lang}.inmemori.com`

    // | path.           | slug  | redirect if
    // | --------------- | ----- | --------
    // | /xxx            | true  | reach.err && !slug.contains('-')
    // | /xxx/xxx        | true  | reach.err && !slug.contains('-')
    // | /               | false | !fallback
    // | /welcome        | false | no
    // | /xxx/xxx/xxx    | false | catchall

    if (subdomain == 'catalog' && app.pathname != '/catalog') {
      console.log('[catalog:redirect]')
      return redirect(res, '/catalog')
    }

    // not slug route
    if (!slug) {
      // redirect if catchall page matched, or root page with no lang fallback, or path not in [routes] that use app component (this file)
      const canRedirect =
        catchall ||
        (pathname == '/' && !i18n.fallback) ||
        !['/home', '/welcome', '/lookup', '/unsubscribe', '/catalog'].includes(pathname)
      redirectUrl = catchRouteInfo(redirectUrl, query)
      if (canRedirect) console.log(`[proxy:redirect:catchall]`, redirectUrl)
      if (canRedirect && app.env.prod) return redirect(res, redirectUrl)

      // route slug matched
    } else if (reach.err) {
      // if slug dont contain -, it's likelly we are not reaching a slug path, even though slug route matches
      // so we redirect to ghost
      if (!/-/.test(slug)) {
        redirectUrl = slugRouteInfo(redirectUrl, query)
        console.log(`[proxy:redirect:slug]`, redirectUrl)
        if (app.env.prod) return redirect(res, redirectUrl)
      }

      // we may want to also redirect here, we first need to gather more data scenario to understand the approriate redirection
      // in the meantime we return a 404
      console.log(`[proxy:study]`, reach.err, slug)
      return {err: reach.err, statusCode: 404}
    }

    const opengraph =
      (page.slug && {
        url: `https://www.inmemori.com/${page.slug}`,
        name: `inmemori`,
        title: `inmemori - ${page.fullname}`,
        description: page.intro
          ? page.intro.replace(/[\r\n]+/g, ' ')
          : `inmemori - ${page.fullname}`,
        image: cloudinary.url(page.image),
        type: 'website',
        locale: page.lang && `${page.lang}_${page.lang.toUpperCase()}`
      }) ||
      {}

    // merge reach in app for convinient picking in props
    app = {...app, ...reach, opengraph}

    return {app, i18n, setCache: !!req}
  } catch (e) {
    console.log('App.getInitialProps() error', e)
    return {err: true, statusCode: 500}
  }
}

export default App

export const withGlobal = (Component) => withI18n(withApp(withToaster(Component)))
